import axios from 'axios'
import FormData from 'form-data'
import config from '@/config'
import { error as logError } from 'Shared/utils/log'

export default {
  _request: async (
    { dispatch },
    { url, method = 'get', headers = {}, data = null }
  ) => {
    await dispatch('authentication/getToken', null, {
      root: true,
    }).then((token) => (headers.authorization = `Bearer ${token}`))

    return axios
      .request({
        baseURL: config.api.artwork.url,
        method,
        url,
        headers,
        data,
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          throw Error(error.response.data.message)
        throw error
      })
  },
  getOne: async ({ getters, commit, state, dispatch }, id) => {
    if (getters.isLoading(id)) return state.loading[id]

    const request = dispatch('_request', { url: `/templates/${id}` })
      .then((response) => {
        if (response.data.success && response.data.data) {
          commit('update', response.data.data)
          return response.data.data
        }
        return null
      })
      .catch((error) => {
        logError(error)
        return null
      })
      .finally(() => commit('unsetLoading', id))
    commit('setLoading', { id, request })
    return request
  },
  upload: async ({ dispatch, commit }, { id, upload }) => {
    const form = new FormData()
    let url = `/templates/${id}`
    if (
      upload.file.type === 'application/json' ||
      upload.file.name.endsWith('.idml')
    ) {
      form.append('data', upload.file)
      form.append('_method', 'patch')
    } else {
      url += '/assets'
      form.append('file', upload.file)
    }
    return dispatch('_request', {
      url,
      method: 'post',
      data: form,
    }).then((response) => {
      if (!response.data.success) {
        throw Error(
          response.data.error ||
            response.data.message ||
            'Update not successful'
        )
      }
      if (url.endsWith('/assets')) {
        dispatch('getOne', id)
      } else {
        commit('update', response.data.data)
      }
      return Promise.resolve()
    })
  },
  replaceFonts: async ({ dispatch, commit }, { id, replacements }) => {
    if (!id) throw Error('Parameter id is required')
    if (
      !replacements ||
      !(replacements instanceof Array) ||
      !replacements.length
    )
      throw Error('Parameter replacements is required')

    return dispatch('_request', {
      url: `/templates/${id}`,
      method: 'patch',
      data: { replaceFonts: replacements },
    }).then((response) => {
      if (!response.data.success) {
        throw Error('Update not successful')
      }
      commit('update', response.data.data)
      return Promise.resolve(response.data.data)
    })
  },
  generate: async (
    { dispatch },
    {
      id,
      demo = false,
      data = null,
      meta = null,
      force = false,
      highDpi = false,
      options = undefined,
      storeAsTestName = undefined,
    }
  ) => {
    if (!id) throw Error('Parameter id is required')
    return dispatch('_request', {
      url: `/artwork/${id}`,
      method: 'post',
      data: {
        data,
        meta,
        demo: demo ? 1 : 0,
        useCache: force ? 0 : 1,
        options: options || {
          hiResPng: highDpi ? 1 : 0,
        },
        storeAsTestName,
      },
    }).then((response) => response.data)
  },
  generatePreview: async ({ dispatch }, id) => {
    if (!id) throw Error('Parameter id is required')
    return dispatch('_request', { url: `/preview/${id}` }).then(
      (response) => response.data
    )
  },
}
