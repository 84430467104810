export default {
  reset: ({ commit }) => {
    commit('loading', false)
    commit('status', '')
    commit('message', '')
    commit('urlImg', [''])
    commit('urlPdf', '')
    commit('duration', null)
    commit('outdated', false)
  },
  fetch: async (
    { state, rootState, rootGetters, commit, dispatch },
    { force = false, highDpi = false }
  ) => {
    if (state.loading) return false
    if (!(rootState.releases.editItem instanceof Object)) return false
    const editItemPreview = rootGetters['releases/editItemPreview']
    if (!editItemPreview.template) return false

    commit('loading', true)
    const timeStart = Date.now()
    return new Promise((resolve, reject) => {
      dispatch(
        'artwork/generate',
        {
          id: editItemPreview.template,
          data: editItemPreview.data,
          meta: editItemPreview.meta,
          force,
          highDpi,
        },
        { root: true }
      )
        .then((data) => {
          commit('status', 'ok')
          commit('message', data)
          if (data.downloadPng instanceof Object) {
            data.downloadPng = Object.values(data.downloadPng)
          }
          if (data.downloadPng instanceof Array) {
            const urlImg = data.downloadPng.map((url) => {
              return `${url}?${data.generated || timeStart}`
            })
            commit('urlImg', urlImg)
          } else {
            commit('urlImg', [''])
          }
          commit('urlPdf', data.downloadPdf || '')
          commit('outdated', false)
          resolve(true)
        })
        .catch((error) => {
          commit('status', 'error')
          commit('message', error.message)
          reject(error)
        })
        .finally(() => {
          commit('loading', false)
          commit('duration', Date.now() - timeStart)
        })
    })
  },
}
