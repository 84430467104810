import { intersection, isEqual, orderBy, toLower, trimEnd } from 'lodash'
import i18n from '@/plugins/i18n'
import monthArrayToString from 'Shared/misc/month-array-to-string'
import parseDigitalTemplate from 'Modules/templates/shared/utils/parseDigitalTemplate'
import RELEASE_STATUS, { filterDefault } from '../constants/release-status'
import { defaultDisplayFile, displayFiles } from '../helpers/display-file'
import orderAllowed from '../permissions/order-allowed'

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
})

export default {
  get: (state) => (id) => {
    return id && state.all && state.all[id] ? state.all[id] : null
  },
  getDisplayFile: (state, getters) => (id) =>
    defaultDisplayFile(getters.get(id)),
  displayFiles: (state, getters) => (id) => displayFiles(getters.get(id)),
  list: (state, getters) =>
    state.list.map(getters.get).sort((a, b) => {
      const label = collator.compare(a.label, b.label)
      if (label !== 0) return label
      return a.number - b.number
    }),
  listFiltered: (state, getters, rootState, rootGetters) => {
    const isBuyer = rootGetters['authentication/isRole']('buyer')
    return getters.list.filter((item) => {
      if (isBuyer) return getters.isOrderAllowed(item)
      return getters.filterStatus.includes(item.status)
    })
  },
  listTotal: (state) => state.list.length,
  filterStatus: (state) => state.filterStatus || filterDefault,
  uploadDir: (state, getters) => (id) => {
    const doc = getters.get(id)
    return doc ? `${doc.owner}/${id}` : null
  },
  editItemPreview: (state) => {
    const editItem = state.editItem instanceof Object ? state.editItem : {}
    return {
      template: Object.prototype.hasOwnProperty.call(editItem, 'template')
        ? editItem.template
        : '',
      data:
        Object.prototype.hasOwnProperty.call(editItem, 'data') &&
        editItem.data instanceof Object
          ? editItem.data
          : {},
      meta: {
        id: editItem.id || null,
        label: editItem.label || null,
        number: editItem.number || null,
      },
    }
  },
  logEntry: (state, getters, rootState) => (action) => {
    const { user } = rootState.authentication
    return {
      timestamp: new Date(),
      user: {
        id: user.id,
        name: user.displayName || '',
      },
      action,
    }
  },
  isOrderAllowed: (state, getters, rootState, rootGetters) => (release) => {
    if (rootGetters['authentication/isSupplier']) return false
    if (!rootGetters['authentication/hasLicense']('print')) return false
    if (!(release instanceof Object)) release = getters.get(release)
    return orderAllowed(release)
  },
  isEditAllowed: (state, getters, rootState, rootGetters) => (id) => {
    const release = getters.get(id)
    if (!release) return false
    const editRel = rootGetters['publications/getEditableRelease'](
      release.publicationId
    )
    return !!editRel && editRel.id === release.id
  },
  isNewReleaseAllowed: (state, getters, rootState, rootGetters) => (id) => {
    const r = getters.get(id)
    if (!r) return false
    return rootGetters['publications/isNewReleaseAllowed'](r.publicationId)
  },
  isDigital: (state, getters) => (id) => {
    const release = getters.get(id)
    return !!(release && release.type === 'digital')
  },
  listConstraints: (state, getters, rootState, rootGetters) => {
    const constraints = []
    if (state.filterType) constraints.push(['type', '==', state.filterType])
    if (state.filterOwner === 'organization') {
      constraints.push([
        'owner',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    } else if (state.filterOwner === 'supplier') {
      constraints.push([
        'supplier',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    }
    if (state.filterCollection)
      constraints.push([`collections.${state.filterCollection}`, '==', true])
    return constraints
  },
  listCollectionId: (state) => {
    const constraints = state.listConstraints
    // check if list is filtered by type
    if (constraints.find(([prop]) => prop === 'type')) return null
    // check if list is filtered by collection
    const filter = constraints.find(([prop]) => prop.startsWith('collections.'))
    if (!filter) return null
    return filter[0].substr(12)
  },
  scrollPosition: (state, getters) => {
    const colId = getters.listCollectionId
    if (!colId) return null
    const { layout, scrollPosition } = state
    return (scrollPosition[layout] && scrollPosition[layout][colId]) || null
  },
  hasListenerListConstraints: (state, getters) => {
    if (!state.listenerAll) return false
    return isEqual(state.listConstraints, getters.listConstraints)
  },
  selected: (state, getters) => {
    return intersection(
      state.selection,
      getters.listFiltered.map((el) => el.id)
    )
  },
  isSelected: (state) => (id) => {
    return id && state.selection.length && state.selection.includes(id)
  },
  compareData:
    (state, getters, rootState, rootGetters) =>
    (id1, id2 = null) => {
      const r1 = getters.get(id1)
      if (!r1) return null
      if (!id2) {
        // get previous release id
        const list = rootGetters['publications/getReleases'](r1.publicationId)
        const idx = list.findIndex((r) => r.number === r1.number) - 1
        if (idx < 0) return null
        id2 = list[idx].id
      }
      const r2 = getters.get(id2)
      if (!r2) return null
      const r1data = r1.data || {}
      const r2data = r2.data || {}
      const diff = {}
      Object.keys(r1data).forEach((id) => {
        if (r1data[id].value) {
          if (!r2data[id] || !r2data[id].value) {
            // added
            diff[id] = { id, new: r1data[id].value }
          } else if (r1data[id].value !== r2data[id].value) {
            // changed
            diff[id] = { id, new: r1data[id].value, old: r2data[id].value }
          }
        }
      })
      Object.keys(r2data).forEach((id) => {
        if (r2data[id].value && (!r1data[id] || !r1data[id].value)) {
          // removed
          diff[id] = { id, old: r2data[id].value }
        }
      })
      if (!r1.template) return Object.values(diff)
      const template = rootGetters['templates/get'](r1.template)
      if (!template || !template.placeholders) return Object.values(diff)
      const placeholders = orderBy(template.placeholders, 'order')
      return placeholders
        .filter((ph) => Object.keys(diff).includes(ph.id))
        .map((ph) => ({ label: ph.label, ...diff[ph.id] }))
    },
  transformCheckGetProperty:
    (state, getters, rootState, rootGetters) => (data) => {
      if (!data || !data.property) return null
      return rootGetters['properties/get'](data.property)
    },
  transformData: (state, getters, rootState, rootGetters) => (data) => {
    const property = getters.transformCheckGetProperty(data)
    if (!property) return null
    if (property.type === 'boolean') return data.valueRaw ? '1' : ''
    const mustTransform =
      property.multiple && (data.index === undefined || data.index === null)
    const canTransform = mustTransform || property.type === 'reference'
    if (!canTransform) return data.valueRaw || ''
    const input =
      data.valueRaw instanceof Array ? data.valueRaw : [data.valueRaw]
    const transform = data.transform || 'csv'
    if (transform === 'csv') return input.join(', ')
    if (transform === 'ndash') return input.join('–')
    if (transform === 'nl') return input.join('\n')
    if (transform === 'li') return input.length ? `› ${input.join('\n› ')}` : ''
    if (transform.indexOf('months') === 0)
      return monthArrayToString(input, transform)
    if (transform === 'extend') {
      if (!data.transformFormat) return null
      return trimEnd(
        input
          .map((id) => {
            const content = rootGetters['contents/get'](id)
            if (!content) return null
            return parseDigitalTemplate(data.transformFormat, content)
          })
          .filter(Boolean)
          .sort((a, b) => {
            if (a < b) return -1
            return a > b ? 1 : 0
          })
          .join(''),
        ', -'
      )
    }
    return null
  },
  mustTransform: (state, getters) => (data) => {
    const property = getters.transformCheckGetProperty(data)
    if (!property) return false
    if (property.type === 'boolean') return true
    if (!property.multiple) return false
    return data.index === undefined || data.index === null
  },
  canTransform: (state, getters) => (data) => {
    if (getters.mustTransform(data)) return true
    const property = getters.transformCheckGetProperty(data)
    return property && property.type === 'reference'
  },
  isTransform: (state, getters) => (data) => {
    return (
      getters.mustTransform(data) ||
      (getters.canTransform(data) && data.transform)
    )
  },
  statusChangeAllowed:
    (state, getters, rootState, rootGetters) => (release, status) => {
      if (release.status === status) return false // not a change

      const isOwner =
        release.owner === rootGetters['authentication/organizationId']
      const isSupplier =
        rootGetters['authentication/isSupplier'] &&
        (rootGetters['authentication/organizationId'] === 'mcs' ||
          release.supplier === rootGetters['authentication/organizationId'])

      if (!isOwner && !isSupplier) return false // foreign release

      if (release.type === 'digital') {
        // valid changes for digital releases
        switch (release.status) {
          case 'pending':
            return status === 'published'
          case 'published':
            return status === 'disabled'
          default:
            return false
        }
      }

      // special handling for disabled print releases
      if (release.status === 'disabled') {
        // only possible change could be 'published' ...
        if (status !== 'published') return false

        // if there is no published release ...
        const getPub = rootGetters['publications/getLatestPublishedRelease']
        if (getPub(release.publicationId)) return false

        // and this is the latest release
        const getLatest = rootGetters['publications/getLatestRelease']
        const latest = getLatest(release.publicationId)
        return latest && latest.id === release.id
      }

      const template = rootGetters['templates/get'](release.template)
      const workflow = (template && template.workflow) || {}

      // valid changes for print releases
      switch (release.status) {
        case 'pending':
          if (isSupplier) return status === 'approval'
          if (!isOwner) return false
          if (status === 'reviewAndPublish') return true
          return status === 'review' && !workflow.disableReview
        case 'review':
          return isSupplier && status === 'approval'
        case 'reviewAndPublish':
          return isSupplier ? status === 'published' : status === 'rejected'
        case 'approval':
          return (
            (isOwner && ['published', 'rejected'].includes(status)) ||
            (isSupplier && ['_approvalReceived', 'rejected'].includes(status))
          )
        case 'published':
          return status === 'disabled'
        default:
          return false
      }
    },
  possibleNewStatus: (state, getters, rootState, rootGetters) => (release) => {
    const list = [...RELEASE_STATUS]
    if (rootGetters['authentication/isSupplier']) {
      const rejectIdx = list.findIndex((s) => s === 'rejected')
      list.splice(rejectIdx === -1 ? 0 : rejectIdx, 0, '_approvalReceived')
    }
    return list.filter((status) => getters.statusChangeAllowed(release, status))
  },
  possibleNewStatusForSelection: (state, getters) => {
    if (!getters.selected.length) return []
    const allowedChanges = getters.selected
      .map(getters.get)
      .filter(Boolean)
      .map(getters.possibleNewStatus)
    return intersection(...allowedChanges)
  },
  deploymentWarning: (state, getters, rootState, rootGetters) => (release) => {
    // check parameter
    if (!release || !release.id) return 'no-release'
    // check release document status
    if (release.status !== 'published') return 'not-published'
    // get publication document
    if (!release.publicationId) return 'no-publication-id'
    const publication = rootGetters['publications/get'](release.publicationId)
    if (!publication) return 'no-publication'
    // check release listed as published in publication document?
    const pRelease =
      (publication.releases && publication.releases[release.id]) || {}
    if (pRelease.status !== 'published') return 'not-published'
    // given release is the only published release / all others are rejected or disabled?
    const unsafe = Object.entries(publication.releases).filter(
      ([itemId, item]) => {
        if (itemId === release.id) return false
        return item.status !== 'disabled' && item.status !== 'rejected'
      }
    )
    return unsafe.length === 0 ? null : 'newer-release'
  },
  editItemLinks: (state, getters, rootState, rootGetters) => {
    const data = (state.editItem && state.editItem.data) || {}
    const tplId = state.editItem && state.editItem.template
    return rootGetters['templates/placeholders'](tplId)
      .map((ph) => {
        const config = data[ph.id]
        if (!config) return null // ph not in release
        if (!config.content || !config.property) return null // not connected
        const { property, lang, index } = config
        const contentPath = rootGetters['contents/propLangIdxToPath'](
          property,
          lang,
          index
        )
        const contentValue = rootGetters['contents/getValue'](
          config.content,
          contentPath
        )
        const dataValue = config.valueRaw || config.value
        const dataValueRaw = config.valueRaw
        const entry = {
          placeholder: ph.id,
          label: ph.label || ph.id,
          dataValue,
          dataValueRaw,
          contentId: config.content,
          contentPath,
          contentValue,
        }
        if (dataValue === undefined) {
          entry.status = null
        } else if (contentValue === null) {
          entry.status = !dataValue
        } else {
          entry.status = isEqual(dataValue, contentValue)
          if (entry.status === false) {
            if (
              typeof dataValue === 'string' &&
              dataValue.replace(/\n|\\n/g, '') === contentValue
            ) {
              // ignore forced line breaks
              entry.status = true
            } else if (dataValueRaw === false && contentValue === false) {
              entry.status = true
            }
          }
        }
        return entry
      })
      .filter(Boolean)
  },
  editItemLinkedContentLabel: (state, getters, rootState, rootGetters) => {
    if (!state.editItem) return null
    const contents = state.editItem.referencedContents
    if (!contents || Object.keys(contents).length !== 1) return null
    const contentId = Object.keys(contents)[0]
    const content = rootGetters['contents/getInherited'](contentId)
    return (content && content.label && content.label[i18n.locale]) || ''
  },
  editItemPlaceholderVisible: (state) => (ph) => {
    if (!ph.test) return true
    const matches = String(ph.test).match(/^!?(\w+)(=!?(\w+))?$/)
    if (!matches) return true
    const value = state.editItem.data[matches[1]]?.value || ''
    const test = matches[3] || null
    if (!test) {
      return matches[0][0] === '!' ? !!value : !value
    }
    const invert = matches[2].substring(0, 2) === '=!'
    return invert !== isEqual(toLower(value), toLower(test))
  },
  listHasDividedPreview: (state, getters, rootState, rootGetters) => {
    const colId = getters.listCollectionId
    const col = rootGetters['collections/get'](colId) || {}
    const tpl = rootGetters['templates/get'](col.releaseDefaultTemplate)
    if (tpl) return tpl.previewDivide
    const relId = rootGetters['collections/getFirstRelease'](colId)
    const rel = getters.get(relId)
    return (rel && rel.previewDivide) || false
  },
  listHasTwoPageTemplate: (state, getters, rootState, rootGetters) => {
    const colId = getters.listCollectionId
    const releaseId = rootGetters['collections/getFirstRelease'](colId)
    const files = displayFiles(getters.get(releaseId))
    return files && files.length === 2
  },
  listShowPageSwitch: (state, getters) => {
    return getters.listHasDividedPreview || getters.listHasTwoPageTemplate
  },
}
